<template>
  <div class="markup-tables flex">
    <va-card class="flex mb-4">
      <va-card-title style="font-size: 1.5rem;">Map View</va-card-title>
      <va-card-content>
        <div class="row">
          <va-card class="flex xs12 md6 offset--md3 map-div" style="margin-bottom: 1.5rem;">
            <GMapMap style="width: 100%; height: 280px;" :center="center" :zoom="zoom" :options="mapOptions" ref="gMap">
              <GMapMarker v-for="stat in markers"
                :key="stat.uid"
                :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
                @click="openMarker(stat.uid)"
              >
                <GMapInfoWindow
                  :closeclick="true"
                  @closeclick="openMarker(null)"
                  :opened="openedMarkerID === stat.uid"
                  >
                  <div id="contet">
                    <div id="siteNotice"></div>
                    <div id="bodyContent">
                      <p>
                        <router-link :to="{ name: 'jointfiles', params: { id: stat.session_id }}" target="_blank">
                          Details
                        </router-link>
                      </p>
                    </div>
                  </div>
                </GMapInfoWindow>
              </GMapMarker>
              <GMapMarker v-for="stat in infoWindows"
                :key="stat.uid"
                :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
                @click="openMarker(stat.uid)"
              >
                <GMapInfoWindow
                  :closeclick="true"
                  @closeclick="openMarker(null)"
                  :opened="openedMarkerID === stat.uid"
                  >
                  <div id="contet">
                    <div id="siteNotice"></div>
                    <h1 id="firstHeading" class="firstHeading">Address</h1>
                    <div id="bodyContent">
                      <p>
                        {{ stat.address }}
                      </p>
                      <p>
                        <router-link :to="{ name: 'jointfiles', params: { id: stat.session_id }}" target="_blank">
                          Details
                        </router-link>
                      </p>
                    </div>
                  </div>
                </GMapInfoWindow>
              </GMapMarker>
              <GMapPolyline
                v-for="flightPath in flightPaths"
                :options="flightPath"
                :path="flightPath.path"
              />
              <GMapMarker
                v-for="lastPoint in lastPoints"
                :icon="'http://maps.google.com/mapfiles/ms/icons/red-dot.png'"
                :position="{ lat: lastPoint.lat, lng: lastPoint.lng }"
              />
              <div v-for="m in mapPoint">
                <GMapMarker
                  v-if="m && m.lat && m.lon"
                  :icon="'http://maps.google.com/mapfiles/ms/micons/man.png'"
                  :position="{ lat: parseFloat(m.lat), lng: parseFloat(m.lon) }"
                />
              </div>
            </GMapMap>
            <div class="flex mb-4">
              <span v-for="(legend, i) in legends">
                <va-popover
                  placement="left"
                  message="Zoom In"
                >
                  <p class="dot" :style="{ 'background-color': legend.strokeColor }" style="cursor: pointer;" @click="zoomIn(legend.lat, legend.lon)"></p>&nbsp;
                </va-popover>
                <router-link :to="{ name: 'jointfiles', params: { id: legend.session_id }}">
                  {{ legend.user }}
                </router-link>
                &nbsp;
                <va-button class="mr-2 mb-2" size="small" icon-right="fa4-stop" @click="playPause(i, false)" v-if="legend.play">
                  Stop&nbsp;&nbsp;
                </va-button>
                <va-button class="mr-2 mb-2" size="small" icon-right="fa4-play" @click="playPause(i, true)" v-else>
                  Simulate&nbsp;&nbsp;
                </va-button>
                <br />
                <br />
              </span>
            </div>
            <va-button class="mr-2 mb-2" size="small" icon-right="fa4-stop" @click="playPauseAll(false)" v-if="groupPlay">
              Stop All&nbsp;&nbsp;
            </va-button>
            <va-button class="mr-2 mb-2" size="small" icon-right="fa4-play" @click="playPauseAll(true)" v-else>
              Simulate All&nbsp;&nbsp;
            </va-button>
          </va-card>
          <va-card class="flex xs12 md12" style="margin-bottom: 1.5rem;">
            <div id="visualization" ref="visualization"></div>
          </va-card>
        </div>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import axios from 'axios'
import { DataSet } from 'vis-data/peer'
import { Timeline } from 'vis-timeline/peer'
import 'vis-timeline/styles/vis-timeline-graph2d.css'
import moment from 'moment-timezone'

export default {
  components: {
    DataSet,
    Timeline
  },
  mounted: async function () {
    // Get current location
    navigator.geolocation.getCurrentPosition((position) => {
      this.center.lat = position.coords.latitude
      this.center.lng = position.coords.longitude
    }, (err) => {
      console.log(error)
    })

    moment.tz.setDefault('UTC')

    const container = document.getElementById("visualization")
    this.timeline = new Timeline(container)
    this.timeline.setOptions(this.options)
    this.timeline.addCustomTime(new Date(), 0)

    const items = []
    const groups = []
    let count = 0

    if (this.selectedItems.length > 0) {
      this.loader = this.$loading.show()
      try {
        await Promise.all(this.selectedItems.map(async (selectedItem) => {
          const headers = {
            Authorization: `Bearer ${this.token}`
          }
          const sessionResponse = await axios.get(`${process.env.VUE_APP_API_URL}/api/jointsessions/${selectedItem.uid}/files?sort_by=-created_at`, { headers })
          let file = sessionResponse.data.files[0]

          if (file && file.stats && file.stats[0]) {
            if (file.type === 'jpeg' || file.type === 'jpg') {
              if (file.stats[0].address) {
                this.infoWindows.push({
                  uid: file.uid,
                  lat: file.stats[0].lat,
                  lon: file.stats[0].lon,
                  address: file.stats[0].address,
                  session_id: selectedItem.uid
                })
              } else {
                this.markers.push({
                  uid: file.uid,
                  lat: file.stats[0].lat,
                  lon: file.stats[0].lon,
                  session_id: selectedItem.uid
                })
              }

              this.startTime = file.stats[0].timestamp
            } else if (file.type === 'obj') {
              if (file.stats[0].address) {
                this.infoWindows.push({
                  uid: file.uid,
                  lat: file.stats[0].lat,
                  lon: file.stats[0].lon,
                  address: file.stats[0].address,
                  session_id: selectedItem.uid
                })
              } else {
                this.markers.push({
                  uid: file.uid,
                  lat: file.stats[0].lat,
                  lon: file.stats[0].lon,
                  session_id: selectedItem.uid
                })
              }

              this.startTime = file.stats[0].timestamp
            } else if (file.type === 'mov') {
              const fileResponse = await axios.get(`${process.env.VUE_APP_API_URL}/api/jointsessions/${selectedItem.uid}/files/${file.uid}`, { headers })
              file = fileResponse.data.file

              const flightPlanCoordinates = []
              const flightPlanCoordinatesTracker = []

              let data = {
                uid: file.uid,
                lat: file.stats[0].lat,
                lon: file.stats[0].lon,
                session_id: selectedItem.uid
              }

              await Promise.all(file.stats.map(async (stat) => {
                this.groupStats.push({
                  id: this.legends.length,
                  type: 'Video Mode',
                  lat: stat.lat,
                  lon: stat.lon,
                  heading: stat.heading,
                  bearing: stat.bearing,
                  timestamp: stat.timestamp
                })
                if (stat.address) {
                  data = {
                    uid: file.uid,
                    lat: stat.lat,
                    lon: stat.lon,
                    address: stat.address,
                    session_id: selectedItem.uid
                  }
                }

                const newCoordinate = {
                  lat: parseFloat(stat.lat),
                  lng: parseFloat(stat.lon)
                }
                const newCoordinateTracker = `${stat.lat},${stat.lon}`
                if (flightPlanCoordinatesTracker.indexOf(newCoordinateTracker) < 0) {
                  flightPlanCoordinates.push(newCoordinate)
                  flightPlanCoordinatesTracker.push(newCoordinateTracker)
                }
              }))

              if (data.address) {
                this.infoWindows.push(data)
              } else {
                this.markers.push(data)
              }

              let strokeColor = this.colors[this.legends.length]  || '#' + ((1<<24)*Math.random() | 0).toString(16)
              strokeColor = strokeColor.length === 7 ? strokeColor : `${strokeColor}0`
              this.legends.push({
                type: 'Video Mode',
                stats: file.stats,
                strokeColor,
                session_id: selectedItem.uid,
                file_id: file.uid,
                user: selectedItem.user,
                lat: file.stats[0].lat,
                lon: file.stats[0].lon,
                startTime: file.stats[0].timestamp,
                endTime: file.stats[file.stats.length - 1].timestamp,
                play: false,
                playPauseTimeout: null
              })
              items.push({
                id: count,
                group: count,
                content: 'Video Mode',
                start: moment(file.stats[0].timestamp).utc().toISOString(),
                end: moment(file.stats[file.stats.length - 1].timestamp).utc().toISOString(),
                lat: file.stats[0].lat,
                lon: file.stats[0].lon,
                style: `background-color: ${strokeColor}; cursor: pointer;`
              })
              groups.push({
                id: count,
                content: selectedItem.user,
                value: count
              })
              count = count + 1
              this.flightPaths.push({
                path: flightPlanCoordinates,
                geodesic: true,
                strokeColor,
                strokeOpacity: 1.0,
                strokeWeight: 2,
              })

              this.lastPoints.push({
                lat: parseFloat(file.stats[file.stats.length - 1].lat),
                lng: parseFloat(file.stats[file.stats.length - 1].lon)
              })

              this.startTime = this.startTime ? (Math.abs(new Date(this.startTime) - new Date(file.stats[0].timestamp)) > 0 ? file.stats[0].timestamp : this.startTime) : file.stats[0].timestamp
              this.endTime = this.endTime ? (Math.abs(new Date(this.endTime) - new Date(file.stats[file.stats.length - 1].timestamp)) > 0 ? this.endTime : file.stats[file.stats.length - 1].timestamp) : file.stats[file.stats.length - 1].timestamp
            } else {
              const filesResponse = await axios.post(`${process.env.VUE_APP_API_URL}/api/jointsessions/${selectedItem.uid}/files-ids`, {}, { headers })
              const files = filesResponse.data.files
              let stats = []

              files.forEach((file) => {
                stats.push(Object.assign(file.first_location, { parent_id: file.uid }))
                stats.push(Object.assign(file.last_location, { parent_id: file.uid }))
              })
              stats = stats.sort((a,b) => {
                return new Date(a.timestamp) - new Date(b.timestamp)
              })

              if (stats[0].address) {
                this.infoWindows.push({
                  uid: file.uid,
                  lat: stats[0].lat,
                  lon: stats[0].lon,
                  address: stats[0].address,
                  session_id: selectedItem.uid
                })
              } else {
                this.markers.push({
                  uid: file.uid,
                  lat: stats[0].lat,
                  lon: stats[0].lon,
                  session_id: selectedItem.uid
                })
              }

              const flightPlanCoordinates = []
              const flightPlanCoordinatesTracker = []
              stats.forEach((stat) => {
                this.groupStats.push({
                  id: this.legends.length,
                  type: 'Telemetry Mode',
                  lat: stat.lat,
                  lon: stat.lon,
                  heading: stat.heading,
                  bearing: stat.bearing,
                  timestamp: stat.timestamp
                })
                const newCoordinate = {
                  lat: parseFloat(stat.lat),
                  lng: parseFloat(stat.lon)
                }
                const newCoordinateTracker = `${stat.lat},${stat.lon}`
                if (flightPlanCoordinatesTracker.indexOf(newCoordinateTracker) < 0) {
                  flightPlanCoordinates.push(newCoordinate)
                  flightPlanCoordinatesTracker.push(newCoordinateTracker)
                }
              })

              let strokeColor = this.colors[this.legends.length]  || '#' + ((1<<24)*Math.random() | 0).toString(16)
              strokeColor = strokeColor.length === 7 ? strokeColor : `${strokeColor}0`
              this.legends.push({
                type: 'Telemetry Mode',
                stats,
                strokeColor,
                session_id: selectedItem.uid,
                file_id: null,
                user: selectedItem.user,
                lat: stats[0].lat,
                lon: stats[0].lon,
                startTime: stats[0].timestamp,
                endTime: stats[stats.length - 1].timestamp,
                play: false,
                playPauseTimeout: null
              })
              items.push({
                id: count,
                group: count,
                content: 'Telemetry Mode',
                start: moment(stats[0].timestamp).utc().toISOString(),
                end: moment(stats[stats.length - 1].timestamp).utc().toISOString(),
                lat: stats[0].lat,
                lon: stats[0].lon,
                style: `background-color: ${strokeColor}; cursor: pointer;`
              })
              groups.push({
                id: count,
                content: selectedItem.user,
                value: count
              })
              count = count + 1
              this.flightPaths.push({
                path: flightPlanCoordinates,
                geodesic: true,
                strokeColor,
                strokeOpacity: 1.0,
                strokeWeight: 2,
              })

              this.lastPoints.push({
                lat: parseFloat(stats[stats.length - 1].lat),
                lng: parseFloat(stats[stats.length - 1].lon)
              })

              this.startTime = this.startTime ? (Math.abs(new Date(this.startTime) - new Date(stats[0].timestamp)) > 0 ? stats[0].timestamp : this.startTime) : stats[0].timestamp
              this.endTime = this.endTime ? (Math.abs(new Date(this.endTime) - new Date(stats[stats.length - 1].timestamp)) > 0 ? this.endTime : stats[stats.length - 1].timestamp) : stats[stats.length - 1].timestamp
            }
          }
        }))

        // Sort group stats
        this.groupStats = this.groupStats.sort((a,b) => {
          return new Date(a.timestamp) - new Date(b.timestamp)
        })

        // Set timeline
        this.items = new DataSet(items)
        this.timeline.setItems(this.items)
        this.groups = new DataSet(groups)
        this.timeline.setGroups(this.groups)

        // Timeline event
        this.timeline.on('select', (properties) => {
          if (properties.items.length > 0) {
            this.zoomIn(items[properties.items].lat, items[properties.items].lon)
          }
        })
        this.timeline.on('doubleClick', (properties) => {
          this.timeline.setCustomTime(properties.time, 0)
          this.timeline.moveTo(properties.time)

          this.legends.forEach((legend, i) => {
            if (new Date(properties.time) >= new Date(legend.startTime) && new Date(properties.time) <= new Date(legend.endTime)) {
              for (let j = 0; j < legend.stats.length; j++) {
                if (new Date(properties.time) <= new Date(legend.stats[j].timestamp)) {
                  this.mapPoint[i] = legend.stats[j]

                  // Map
                  const pointA = new this.google.maps.LatLng(parseFloat(legend.stats[j].lat), parseFloat(legend.stats[j].lon))
                  const distance = 20 // in meters
                  let bearing = parseFloat(legend.stats[j].heading)
                  let pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

                  const lineSymbol = {
                    path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                  }

                  this.directionPath = {
                    path: [
                      {
                        lat: pointA.lat(),
                        lng: pointA.lng()
                      },
                      {
                        lat: pointB.lat(),
                        lng: pointB.lng()
                      }
                    ],
                    icons: [
                      {
                        icon: lineSymbol,
                        offset: '100%',
                      },
                    ],
                    geodesic: true,
                    strokeColor: '#FA86C4',
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                  }

                  bearing = parseFloat(legend.stats[j].course)
                  pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

                  this.directionPathCourse = {
                    path: [
                      {
                        lat: pointA.lat(),
                        lng: pointA.lng()
                      },
                      {
                        lat: pointB.lat(),
                        lng: pointB.lng()
                      }
                    ],
                    icons: [
                      {
                        icon: lineSymbol,
                        offset: '100%',
                      },
                    ],
                    geodesic: true,
                    strokeColor: '#006721',
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                  }
                  break
                }
              }
            }
          })
        })
        this.timeline.on('timechanged', (properties) => {
          this.timeline.moveTo(properties.time)

          this.legends.forEach((legend, i) => {
            if (new Date(properties.time) >= new Date(legend.startTime) && new Date(properties.time) <= new Date(legend.endTime)) {
              for (let j = 0; j < legend.stats.length; j++) {
                if (new Date(properties.time) <= new Date(legend.stats[j].timestamp)) {
                  this.mapPoint[i] = legend.stats[j]

                  // Map
                  const pointA = new this.google.maps.LatLng(parseFloat(legend.stats[j].lat), parseFloat(legend.stats[j].lon))
                  const distance = 20 // in meters
                  let bearing = parseFloat(legend.stats[j].heading)
                  let pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

                  const lineSymbol = {
                    path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                  }

                  this.directionPath = {
                    path: [
                      {
                        lat: pointA.lat(),
                        lng: pointA.lng()
                      },
                      {
                        lat: pointB.lat(),
                        lng: pointB.lng()
                      }
                    ],
                    icons: [
                      {
                        icon: lineSymbol,
                        offset: '100%',
                      },
                    ],
                    geodesic: true,
                    strokeColor: '#FA86C4',
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                  }

                  bearing = parseFloat(legend.stats[j].course)
                  pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

                  this.directionPathCourse = {
                    path: [
                      {
                        lat: pointA.lat(),
                        lng: pointA.lng()
                      },
                      {
                        lat: pointB.lat(),
                        lng: pointB.lng()
                      }
                    ],
                    icons: [
                      {
                        icon: lineSymbol,
                        offset: '100%',
                      },
                    ],
                    geodesic: true,
                    strokeColor: '#006721',
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                  }
                  break
                }
              }
            }
          })
        })

        // Set time
        if (this.startTime) {
          this.timeline.setCustomTime(this.startTime, 0)
        }

        this.$refs.gMap.$mapPromise.then(() => {
          this.google = window.google
        })

        this.loader.hide()
      } catch (error) {
        this.loader.hide()
        this.$router.push({ name: 'login', query: { redirect: '/pages/jointsessions/grid' } })
      }
    } else {
      this.$router.push({ name: 'jointsessions' })
    }
  },
  data () {
    return {
      loader: null,
      token: this.$store.state.token,
      selectedItems: this.$store.state.selectedItems,
      markers: [],
      infoWindows: [],
      flightPaths: [],
      lastPoints: [],
      center: {
        lat: 0.000000,
        lng: 0.000000
      },
      zoom: 4,
      mapPoint: [],
      legends: [],
      startTime: null,
      endTime: null,
      google: null,
      groupStats: [],
      groupPlay: false,
      groupPlayPauseTimeout: null,
      timeline: null,
      items: null,
      groups: null,
      options: {},
      colors: [
        '#8c0303',
        '#035c57',
        '#03255c',
        '#6e0367',
        '#fa4b00',
        '#bdaa02',
        '#6e0339',
        '#02c292',
        '#0206d6',
        '#02d6d3'
      ],
      openedMarkerID: null,
      mapOptions: {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        styles: [
          {
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#1d2c4d"
              }
            ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#8ec3b9"
              }
            ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#1a3646"
              }
            ]
          },
          {
            "featureType": "administrative.country",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#4b6878"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#64779e"
              }
            ]
          },
          {
            "featureType": "administrative.province",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#4b6878"
              }
            ]
          },
          {
            "featureType": "landscape.man_made",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#334e87"
              }
            ]
          },
          {
            "featureType": "landscape.natural",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#023e58"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#283d6a"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#6f9ba5"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#1d2c4d"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "color": "#023e58"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#3C7680"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#304a7d"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#98a5be"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#1d2c4d"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#2c6675"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#255763"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#b0d5ce"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#023e58"
              }
            ]
          },
          {
            "featureType": "transit",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#98a5be"
              }
            ]
          },
          {
            "featureType": "transit",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#1d2c4d"
              }
            ]
          },
          {
            "featureType": "transit.line",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "color": "#283d6a"
              }
            ]
          },
          {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#3a4762"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#0e1626"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#4e6d70"
              }
            ]
          }
        ]
      }
    }
  },
  computed: {
  },
  methods: {
    zoomIn (lat, lon) {
      this.center = {
        lat: parseFloat(lat),
        lng: parseFloat(lon)
      }
      this.zoom = 15
    },
    playPause (c, play) {
      if (play) {
        this.legends.forEach((legend) => {
          if (legend.playPauseTimeout) {
            clearInterval(legend.playPauseTimeout)
            legend.play = false
          }
        })
        this.legends[c].play = true

        let i = 0
        let intervalSeconds = 34

        if (this.legends[c].type === 'Telemetry Mode') {
          intervalSeconds = 1000
        }

        const stats = this.legends[c].stats
        this.legends[c].playPauseTimeout = setInterval(() => {
          if (!this.mapPoint[c] || this.mapPoint[c].lat !== stats[i].lat || this.mapPoint[c].lon !== stats[i].lon) {
            this.mapPoint[c] = stats[i]

            // Timeline
            this.timeline.setCustomTime(this.mapPoint[c].timestamp, 0)
            this.timeline.moveTo(this.mapPoint[c].timestamp)

            // Map
            const pointA = new this.google.maps.LatLng(parseFloat(stats[i].lat), parseFloat(stats[i].lon))
            const distance = 20 // in meters
            let bearing = parseFloat(stats[i].heading)
            let pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

            const lineSymbol = {
              path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            }

            this.directionPath = {
              path: [
                {
                  lat: pointA.lat(),
                  lng: pointA.lng()
                },
                {
                  lat: pointB.lat(),
                  lng: pointB.lng()
                }
              ],
              icons: [
                {
                  icon: lineSymbol,
                  offset: '100%',
                },
              ],
              geodesic: true,
              strokeColor: '#FA86C4',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }

            bearing = parseFloat(stats[i].course)
            pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

            this.directionPathCourse = {
              path: [
                {
                  lat: pointA.lat(),
                  lng: pointA.lng()
                },
                {
                  lat: pointB.lat(),
                  lng: pointB.lng()
                }
              ],
              icons: [
                {
                  icon: lineSymbol,
                  offset: '100%',
                },
              ],
              geodesic: true,
              strokeColor: '#006721',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }
          }

          i = i + 1

          if (i >= stats.length) {
            if (this.legends[c].playPauseTimeout) {
              clearInterval(this.legends[c].playPauseTimeout)
              this.legends[c].play = false
            }
          }
        }, intervalSeconds)

      } else {
        if (this.legends[c].playPauseTimeout) {
          clearInterval(this.legends[c].playPauseTimeout)
        }

        this.legends[c].play = false
      }
    },
    playPauseAll (play) {
      if (play) {
        this.groupPlay = true

        let i = 0
        let intervalSeconds = 34

        const stats = this.groupStats
        this.groupPlayPauseTimeout = setInterval(() => {
          const c = stats[i].id
          if (!this.mapPoint[c] || this.mapPoint[c].lat !== stats[i].lat || this.mapPoint[c].lon !== stats[i].lon) {
            if (stats[i].type === 'Telemetry Mode') {
              intervalSeconds = 1000
            }
            this.mapPoint[c] = stats[i]

            // Timeline
            this.timeline.setCustomTime(this.mapPoint[c].timestamp, 0)
            this.timeline.moveTo(this.mapPoint[c].timestamp)

            // Map
            const pointA = new this.google.maps.LatLng(parseFloat(stats[i].lat), parseFloat(stats[i].lon))
            const distance = 20 // in meters
            let bearing = parseFloat(stats[i].heading)
            let pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

            const lineSymbol = {
              path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            }

            this.directionPath = {
              path: [
                {
                  lat: pointA.lat(),
                  lng: pointA.lng()
                },
                {
                  lat: pointB.lat(),
                  lng: pointB.lng()
                }
              ],
              icons: [
                {
                  icon: lineSymbol,
                  offset: '100%',
                },
              ],
              geodesic: true,
              strokeColor: '#FA86C4',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }

            bearing = parseFloat(stats[i].course)
            pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

            this.directionPathCourse = {
              path: [
                {
                  lat: pointA.lat(),
                  lng: pointA.lng()
                },
                {
                  lat: pointB.lat(),
                  lng: pointB.lng()
                }
              ],
              icons: [
                {
                  icon: lineSymbol,
                  offset: '100%',
                },
              ],
              geodesic: true,
              strokeColor: '#006721',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }
          }

          i = i + 1

          if (i >= stats.length) {
            if (this.groupPlayPauseTimeout) {
              clearInterval(this.groupPlayPauseTimeout)
              this.groupPlay = false
            }
          }
        }, intervalSeconds)

      } else {
        if (this.groupPlayPauseTimeout) {
          clearInterval(this.groupPlayPauseTimeout)
        }

        this.groupPlay = false
      }
    },
    openMarker(id) {
      this.openedMarkerID = id
    }
  },
}
</script>

<style lang="scss">
  .markup-tables {
    .table-wrapper {
      overflow: auto;
    }

    .va-table {
      width: 100%;
    }
  }

  .table-example--pagination {
    text-align: center;
    text-align: -webkit-center;
    padding-top: 1rem;
  }

  .dot {
    height: 1rem;
    width: 2rem;
    display: inline-block;
    margin-right: 1rem;
  }

  .gm-fullscreen-control {
    background-color: var(--va-white) !important;
  }

  .gmnoprint > .gmnoprint > div {
    background-color: var(--va-white) !important;
  }

  .vis-time-axis .vis-text {
    color: var(--va-dark);
  }

  .vis-labelset .vis-label {
    color: var(--va-dark);
  }

  .vis-item {
    color: var(--va-dark);
  }

  .map-div {
    background-color: var(--va-background) !important;
  }
</style>
